import {AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {Table} from '../member-manager/member-manager.component';
import {FieldType} from '../member-addedit/member-addedit.component';
import {MatSelectChange} from '@angular/material/select';
import {UtilsService} from '../../../services/utils/utils.service';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {VendorProductsService} from '../../../services/vendorproducts/vendorproducts.service';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {UsersInsuranceService} from '../../../services/usersinsurance/users.insurance.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

export const enrollAddedFields = [
  {name: 'Type', label: 'Coverage Type', fieldType: FieldType.F_DROPDOWN, values: ['Single:single', 'Partner:partner', 'Single Parent Family:spf', 'Two Parent Family:tpf'], required: true},
  {name: 'Relationship', label: 'Relationship', fieldType: FieldType.F_DROPDOWN, values: ['Self:self', 'Partner:partner', 'Dependent:dependent'], required: true},
  {name: 'Primary SSN', label: 'Primary Social Security Number', fieldType: FieldType.F_SSN, required: false, invisible: (form: any) => !form.get('Relationship').value || form.get('Relationship').value === 'self'},
  {name: 'Product', label: 'Product', fieldType: FieldType.F_DROPDOWN, values: [], required: true},
  {name: 'Hire Date', label: 'Hire Date', fieldType: FieldType.F_PASTDATE, required: false},
  {name: 'Enrollment Date', label: 'Enrollment Date', fieldType: FieldType.F_FUTUREDATE, dateCutoffField: 'Enrollment Date', required: true},
  {name: 'Start Date', label: 'Start Date', fieldType: FieldType.F_FUTUREDATE, dateCutoffField: 'Enrollment Date', required: true}
];

@Component({
  selector: 'app-client-enrollment',
  templateUrl: './client-enrollment.component.html',
  styleUrls: ['./client-enrollment.component.scss']
})

export class ClientEnrollmentComponent implements OnChanges, AfterViewInit {

  @Input() clientId = '';
  @ViewChild('search') search: ElementRef | undefined;

  public reload = false;
  public itemCount = 0;

  protected products: any;
  public table!: Table;

  private baseTable: Table = {
    title: 'Enrolled Member',
    addEditFields: [
      {name: 'Given Name', label: 'First Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'Family Name', label: 'Last Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'Address 1', label: 'Address 1', fieldType: FieldType.F_STRING, required: true},
      {name: 'Address 2', label: 'Address 2', fieldType: FieldType.F_STRING},
      {name: 'City', label: 'City', fieldType: FieldType.F_STRING, required: true},
      {name: 'State', label: 'State', fieldType: FieldType.F_STRING, required: true},
      {name: 'ZIP', label: 'ZIP Code', fieldType: FieldType.F_STRING, required: true},
      {name: 'Email', label: 'Email Address', fieldType: FieldType.F_EMAIL, required: true},
      {name: 'Phone', label: 'Phone Number', fieldType: FieldType.F_PHONE, required: false},
      {name: 'Date of Birth', label: 'Date of Birth', fieldType: FieldType.F_PASTDATE, required: true},
      {name: 'Gender', label: 'Gender', fieldType: FieldType.F_DROPDOWN, values: ['Male', 'Female'], required: true},
      {name: 'SSN', label: 'Social Security Number', fieldType: FieldType.F_SSN, required: true},
      {name: 'Smoking', label: 'Smoking', fieldType: FieldType.F_DROPDOWN, values: ['Yes', 'No'], required: true},
        ...enrollAddedFields
    ],
    columnsToDisplay: [
      {
        data: (row: { [x: string]: any; }) => row['Given Name'],
        label: 'First Name',
      },
      {
        data: (row: { [x: string]: any; }) => row['Family Name'],
        label: 'Last Name'
      },
      {
        data: (row: { [x: string]: any; }) => row.Type,
        label: 'Coverage Type'
      },
      {
        data: (row: { [x: string]: any; }) => row.Relationship,
        label: 'Relationship'
      },
      {
        data: (row: { [x: string]: any; }) => this.utilsService.vendorProductSlugToDisplay(row.Product),
        label: 'Product'
      },
      {
        data: (row: { [x: string]: any; }) => this.utilsService.vendorProductSlugToDisplay(row.primaryName),
        label: 'Primary'
      },
      {
        data: (row: { [x: string]: any; }) => (row.metadata.updated ? 'Updated ' : '') + (row.metadata.synced ? (row.metadata.updated ? 'Needs Sync' : 'Synced') : row.metadata.invited ? (row.userId ? 'Signed Up' : 'Invited') : '') + (row.metadata.deletepending ? ', pending delete' : ''),
        label: 'State'
      }
    ],
    menuItems: [
      {name: 'Unenroll', click: this.unenroll.bind(this)},
      {name: 'Invite via Email', click: this.emailinvite.bind(this)}
    ],
    templateUrl: '/assets/files/Wallit-enrollment.xlsx',
    where: {
      limit: 50,
      offset: 0,
      sort: '',
      filter: {}
    }
  };

  constructor(
      private utilsService: UtilsService,
      private teamCompaniesService: TeamCompaniesService,
      private vendorProductsService: VendorProductsService,
      private usersInsuranceService: UsersInsuranceService,
      private snackBar: MatSnackBar
  ) {
    this.usersInsuranceService.insuranceStateCountObservable.subscribe(result => {
      this.itemCount = result.enrolled;
    });
  }

  ngAfterViewInit(): void {
    fromEvent(this.search?.nativeElement, 'keyup').pipe(
        filter(Boolean),
        debounceTime(300),
        distinctUntilChanged(),
        tap((): void => {
          Object.assign(this.table.where.filter, {search: this.search?.nativeElement.value.trim().toLowerCase()});
          this.reload = !this.reload;
        })
    ).subscribe();
  }

  ngOnChanges(): void {
    if (!this.clientId) {
      return;
    }
    this.teamCompaniesService.getCompany(this.clientId).then(company => {
      this.vendorProductsService.getAllVendorProducts().then(products => {
        this.products = products.filter((product: { id: string; }) => company.payrollConfig.products?.find((productId: string) => productId === product.id));
        const productTableField = _.find(this.baseTable.addEditFields, field => field.name === 'Product');
        // @ts-ignore
        productTableField.values = this.products.map((product: any) => `${product.vendorName} ${product.productName}:${product.vendorSlug}-${product.productSlug}`);
        this.table = this.baseTable;
      });
    });
  }

  unenroll(item: any): Promise<boolean> {
    return this.usersInsuranceService.modifyInsuranceState(item.id, { state: 'eligible', metadata: Object.assign({}, item.metadata, {deletepending: true})}).then(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Member unenrolled and set to eligible' });
      return true;
    });
  }

  emailinvite(item: any): Promise<any> {
      return this.usersInsuranceService.invite(this.clientId, 'enrolled', [item.id]).then(result => {
        this.snackBar.openFromComponent(SnackBarComponent, {data: `Invitation email sent`});
      }).catch(error => {
        this.snackBar.openFromComponent(SnackBarComponent, {data: `Error sending email: ${error.message}`});
      });
  }

  setEmailFilter(event: MatSelectChange): void {
    Object.assign(this.table.where.filter, {invitation: event.value});
    this.reload = !this.reload;
  }

  setProductFilter(event: MatSelectChange): void {
    Object.assign(this.table.where.filter, {product: event.value});
    this.reload = !this.reload;
  }

  tableUpdated(count: number): void {
    this.itemCount = count;
  }

  pageEvent(event: any): void {
    this.table.where.limit = event.pageSize;
    this.table.where.offset = event.pageIndex * event.pageSize;
    this.reload = !this.reload;
  }

}