import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class VendorGroupsProductsService {

  constructor(
      private restangular: Restangular
  ) { }

  getAllVendorGroupProducts(): Promise<any> {
    return this.restangular.one('/vendors/groups/products').get().toPromise();
  }

  createVendorGroupProduct(productSlug: string, metadata: any): Promise<any> {
    return this.restangular.one('/vendors/groups/products', productSlug).customPOST({
      ...metadata,
    }).toPromise();
  }

  modifyVendorGroupProduct(id: string, fields: any): Promise<any> {
    return this.restangular.one('/vendors/groups/products', id).customPUT({
      ...fields
    }).toPromise();
  }

  deleteVendorGroupProduct(id: string): Promise<any> {
    return this.restangular.one('vendors/groups/products', id).remove().toPromise();
  }

}
