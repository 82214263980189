import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class VendorGroupsPlansService {

  constructor(
      private restangular: Restangular
  ) { }

  getAllVendorGroupPlans(): Promise<any> {
    return this.restangular.one('/vendors/groups/plans').get().toPromise();
  }

  createVendorGroupPlan(productId: string, metadata: any): Promise<any> {
    return this.restangular.one('/vendors/groups/plans', productId).customPOST({
      ...metadata,
    }).toPromise();
  }

  modifyVendorGroupPlan(id: string, fields: any): Promise<any> {
    return this.restangular.one('/vendors/groups/plans', id).customPUT({
      ...fields
    }).toPromise();
  }

  deleteVendorGroupPlan(id: string): Promise<any> {
    return this.restangular.one('vendors/groups/plans', id).remove().toPromise();
  }

}
