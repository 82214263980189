import {Component, Input, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {Purchase, Transaction, TransactionsService} from '../../../services/transactions/transactions.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TeamsService} from '../../../services/teams/teams.service';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {SessionService} from '../../../services/session/session.service';

@Component({
  selector: 'app-analytics-toapprove',
  templateUrl: './analytics-toapprove.component.html',
  styleUrls: ['./analytics-toapprove.component.scss']
})
export class AnalyticsToapproveComponent implements OnInit {

  SCROLL_CHUNK = 1000;

  @Input() analytics: any;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public columnsToDisplay = [ 'transactionDate', 'user', 'employeeNumber', 'categories', 'amount', 'taxable', 'status', 'action' ];
  public statuses = this.transactionsService.transactionStatuses;
  public members: any;
  public team: any;
  public items = new MatTableDataSource<Transaction>();
  private page = 0;
  private teamId = '';
  private searchValue = '';
  public userId = 'all';
  public form: UntypedFormGroup;
  public fileName = '';

  constructor(
      private transactionsService: TransactionsService,
      private teamsService: TeamsService,
      private route: ActivatedRoute,
      private formBuilder: UntypedFormBuilder,
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService,
      private spinnerService: SpinnerService,
      private sessionService: SessionService,
  ) {
    this.form = this.formBuilder.group({
          member: new UntypedFormControl(0),
          status: new UntypedFormControl(0)
        }
    );
    this.form.valueChanges.subscribe(() => {
      const memberIndex = this.form.get('member')?.value;
      this.userId = memberIndex ? this.members[memberIndex].userId : 'all';
      this.loadData().then(results => {
        this.items.data = [];
        this.items.data = results;
      });
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.userId) {
        this.userId = params.userId;
      }
      this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
        if (wellspace) {
          // @ts-ignore
          this.teamId = wellspace.id;
          this.team = wellspace;
          if (this.team.transactionPrivacy === 0) {
            this.columnsToDisplay.splice(this.columnsToDisplay.length - 1, 0, 'name');
          }
          this.teamsService.getTeamMembers(this.teamId).then(members => {
            this.members = members;
            this.members.unshift({firstName: 'All', lastName: 'members'});
            const index = members.findIndex((m: any) => m.userId === this.userId);
            this.form.get('member')?.setValue(index < 0 ? 0 : index, {emitEvent: false});
            const member = this.members[index < 0 ? 0 : index];
            // @ts-ignore
            this.fileName = `${wellspace.name} reimbursements ${member.firstName} ${member.lastName}`;
            this.loadData().then(results => {
              this.items.data = results;
              this.items.sort = this.sort;
            });
          });
        }
      });
    });
  }

  private loadData(): Promise<any> {
    return this.transactionsService.listTransactions(this.teamId, this.userId, this.page, this.SCROLL_CHUNK, this.searchValue, this.form.get('status')?.value).then(results => {
      results.forEach((result: any) => {
        result.employeeNumber = this.members.find((member: any) => member.userId === result.userId).employeeNumber;
      });
      return results;
    });
  }

  filterItems(event: any): void {
    this.searchValue = event.target.value;
    this.loadData().then(results => {
      this.items.data = results;
    });
  }

  async reimburseTransaction(item: Transaction): Promise<void> {
    const userId = this.sessionService.getUserId();
    try {
      this.spinnerService.show('Please wait...');
      await this.transactionsService.reimburseTransaction(this.teamId, userId, item.transactionId, false);
      this.loadData().then(results => this.items.data = results);
      this.spinnerService.hide();
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Transaction has been set for reimbursement'});
    } catch (error: any) {
      if (error.status === 422) {
          this.spinnerService.hide();
          const confirmPartialReimbursement = await this.uiAlertService.presentAlertConfirm(`This user's Allowance Balance isn't enough to be able to do a full reimbursement.<br>Do you wish to do a partial reimbursement instead?<br>This will consume the user's balance: $${error.error.response.balance}.`);
          if (confirmPartialReimbursement) {
            this.spinnerService.show('Please wait...');
            await this.transactionsService.reimburseTransaction(this.teamId, userId, item.transactionId, true);
            this.loadData().then(results => this.items.data = results);
            this.snackBar.openFromComponent(SnackBarComponent, {data: 'Transaction partially reimbursed'});
            this.spinnerService.hide();
          }
          return;
      } else if (error.status === 400) {
        this.spinnerService.hide();
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'User doesn\'t have enough allowance balance'});
        return;
      }
      this.spinnerService.hide();
      this.snackBar.openFromComponent(SnackBarComponent, {data: error.error && error.error.message ? error.error.message : 'An error has occurred while trying to reimburse the transaction.'});
    }
  }

  denyTransaction(item: Purchase): void {
    this.uiAlertService.presentFieldInput('Reimbursement Denial Details', 'Info', '').then(result => {
      if (result) {
        this.transactionsService.denyTransaction(item.transactionId, this.teamId, result).then(() => {
          this.loadData().then(res => {
            this.items.data = res;
          });
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Purchase reimbursement has been denied'});
        }).catch(err => {
          console.error('Error in deny transaction', err);
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Error in deny transaction'});
        });
      }
    });
  }

  getStatus(item: any): string {
    if (item.teamPaid) {
      return item.teamAmountReimbursed === 0 ? 'Reimbursement denied' : `$${item.teamAmountReimbursed} Reimbursed`;
    } else {
      if (item.reimburse) {
        return item.teamEligible ? 'Reimbursement approved' : 'Reimbursement requested';
      }
    }
    return '';
  }

}

@Pipe({
  name: 'getStatus',
  pure: true
})
export class GetStatusPipe implements PipeTransform {

  transform(item: any, thisArg: AnalyticsToapproveComponent): string {
    return thisArg.getStatus(item);
  }

}


