<div *ngIf="team" class="planwrapper">
    <div class="section">Automated Bonuses</div>
    <table class="plans">
        <tr class="row" *ngFor="let program of programSettings">
            <td class="toggle"><mat-slide-toggle [checked]="program.enabled" (change)="toggleProgram($event, program)"></mat-slide-toggle></td>
            <td class="amount">{{program.amount | currency}}</td>
            <td class="programname">{{program.name}}</td>
            <td class="description">{{program.description}}</td>
            <td><button mat-raised-button color="accent" class="editbutton" [disabled]="!program.enabled" (click)="editProgram(program)">Edit</button></td>
        </tr>
    </table>
    <div class="section">Automated Bonus History</div>
    <span *ngIf="historyItems.length === 0" class="norows">No automatic bonuses have been paid</span>
    <table class="history" *ngIf="historyItems.length > 0">
        <tr class="historyheader">
            <td class="firstheader">Sent To</td><td>Type</td><td>Amount</td><td class="lastheader">Date Sent</td>
        </tr>
        <tr *ngFor="let historyItem of historyItems">
            <td>{{historyItem.firstName}} {{historyItem.lastName}}</td>
            <td>{{historyItem.type}}</td>
            <td>{{historyItem.amount | currency}}</td>
            <td class="lastcol">{{historyItem.createdAt | date}}</td>
        </tr>
    </table>
    <div class="section">
        <div class="sectionitems">
            <span>Scheduled Rewards</span>
            <button mat-raised-button color="accent" class="editbutton" (click)="removeAllRewards()">Remove All</button>
        </div>
    </div>
    <span *ngIf="scheduledItems.length === 0" class="norows">There are no pending scheduled rewards</span>
    <table class="history" *ngIf="scheduledItems.length > 0">
        <tr class="historyheader">
            <td class="firstheader">Send on</td><td>Amount</td><td>Message</td><td class="lastheader">Recipient</td><td></td>
        </tr>
        <tr *ngFor="let scheduledItem of scheduledItems">
            <td>{{scheduledItem.date | date }}</td>
            <td>{{scheduledItem.amount | currency}}</td>
            <td *ngIf="this.isAfterCurrentDate(scheduledItem.date)">{{scheduledItem.message}}</td>
            <td *ngIf="!this.isAfterCurrentDate(scheduledItem.date)" class="failedpayment">Payment failed!</td>
            <td>{{scheduledItem.email}}</td>
            <td class="lastcol"><button mat-raised-button color="accent" class="editbutton" (click)="removeItem(scheduledItem)">Remove</button></td>
        </tr>
    </table>
    <div class="section">
        <div class="sectionitems">
            <span>Rewards History</span>
        </div>
    </div>
    <span *ngIf="itemsSent.length === 0" class="norows">There are no sent rewards</span>
    <table class="history" *ngIf="itemsSent.length > 0">
        <tr class="historyheader">
            <td class="firstheader">Sent on</td><td>Amount</td><td>Message</td><td class="lastheader">Recipient</td><td></td>
        </tr>
        <tr *ngFor="let reward of itemsSent">
            <td>{{reward.dateSent | date}}</td>
            <td>{{reward.amount | currency}}</td>
            <td>{{reward.message}}</td>
            <td>{{reward.email}}</td>
            <td class="lastcol"><button mat-raised-button [ngStyle]="{opacity: 0}" [disabled]="true">Remove</button></td>
        </tr>
    </table>
    <div class="section">
        <div class="sectionitems">
            <span>One Time Rewards History</span>
        </div>
    </div>
    <span *ngIf="oneTimeRewards?.length === 0" class="norows">There are no sent One Time Rewards</span>
    <table class="history" *ngIf="oneTimeRewards && oneTimeRewards.length > 0">
        <tr class="historyheader">
            <td class="firstheader">Sent on</td>
            <td>Amount</td>
            <td>Message</td>
            <td class="lastheader">Recipient</td>
            <td></td>
        </tr>
        <tr *ngFor="let reward of oneTimeRewards">
            <td>{{ reward?.transactionDate ? (reward.transactionDate | date) : '---' }}</td>
            <td>{{ reward?.amount ?? '---' | currency}}</td>
            <td>{{ reward?.description ?? '---' }}</td>
            <td>{{ reward?.recipient ?? '---' }}</td>
            <td class="lastcol">
                <button mat-raised-button [ngStyle]="{opacity: 0}" [disabled]="true">Remove</button>
            </td>
        </tr>
    </table>
</div>