import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-vendor-groups',
  templateUrl: './vendor-groups.component.html',
  styleUrls: ['./vendor-groups.component.scss']
})
export class VendorGroupsComponent implements OnInit {

  public links = [
    { title: 'Products', link: '/vendorgroups/products'},
    { title: 'Plans', link: '/vendorgroups/plans'},
    { title: 'Rates', link: '/vendorgroups/rates'},
  ];

  public activeLink: any;

  constructor(
      private router: Router
  ) { }

  ngOnInit(): void {
    const parsedUrl = this.router.parseUrl(this.router.url);
    const urlSegments = parsedUrl.root.children.primary.segments;
    urlSegments.length = 2;
    this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
  }

  gotoTab(link: string): void {
    this.router.navigate([link]);
    this.activeLink = link;
  }

}
