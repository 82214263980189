import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SessionService} from '../../services/session/session.service';
import {ActivatedRoute, Router} from '@angular/router';
// @ts-ignore
import packageInfo from '../../../../package.json';
import {AppSettings} from '../../app.settings';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {BrandsService} from '../../services/brands/brands.service';
import {CodeInputComponent} from 'angular-code-input';
import {CognitoMfaService} from '../../services/cognito/cognito-mfa.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('codeInput') codeInput !: CodeInputComponent;


  public form: UntypedFormGroup;
  public error = '';
  public info = packageInfo.version;
  public logoUrl = '';
  protected readonly AppSettings = AppSettings;

  public isIntegrationAdmin = AppSettings.isIntegration();
  public isCustomerSupport = AppSettings.isCustomerSupport();
  public isBrokerAdmin = AppSettings.isBroker();
  hidePassword = true;

  loginWithoutPassword = false;
  emailCodeInput = false;
  resendEmailCodeTimerCounter = 60;
  showResendEmailCodeTimerCounter = false;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private sessionService: SessionService,
      private router: Router,
      private route: ActivatedRoute,
      private uiAlertService: UiAlertService,
      private brandsService: BrandsService,
      private cognitoMFAService: CognitoMfaService,
  ) {
    this.form = this.formBuilder.group({
          username: new UntypedFormControl('', [Validators.required]),
          password: new UntypedFormControl('', [Validators.required])
        }
    );
  }

  ngOnInit(): void {
    this.brandsService.init().then(() => {
      this.logoUrl = this.brandsService.getLogoOnWhite();
      this.route.params.subscribe(params => {
        if (params.showTimeout === 'true') {
          this.uiAlertService.presentMessage('You have been logged out due to inactivity');
          this.router.navigate(['login']);
        }
      });
    });
  }

  loginClicked(): void {
      this.sessionService.loginUserPassword(this.form.get('username')?.value, this.form.get('password')?.value).then(_ => {
        void this.router.navigate([this.sessionService.loginUrl ? this.sessionService.loginUrl : '']);
      }
      ).catch(error => {
        console.error('Error in login method', error);
        this.error = this.isIntegrationAdmin ? error?.error?.errors[0]?.message : error?.error?.errors;
      });
    }

  async sendEmail(): Promise<void> {
    const cognitoUserResponse: any = await this.cognitoMFAService.cognitoMFASignInWithoutPassword(this.form.get('username')?.value);
    try {
      if (cognitoUserResponse && cognitoUserResponse.challengeName === 'CUSTOM_CHALLENGE') {
        this.emailCodeInput = true;
      }
    } catch (error) {
      this.error = `Error in send email ${error}`;
    }
  }

  async onCodeCompleted(c: string): Promise<void> {
    try {
      return this.sessionService.verifyEmailCode(
          this.form.get('username')?.value, c
      );
    } catch (error) {
      this.error = 'The code is incorrect';
      this.codeInput.reset();
      setTimeout(() => this.error = '', 3000);
    }
  }

  resendEmailCodeTimer(): void {
    this.resendEmailCodeTimerCounter = 60;
    this.showResendEmailCodeTimerCounter = true;
    const interval = setInterval(() => {
      this.resendEmailCodeTimerCounter--;

      if (this.resendEmailCodeTimerCounter <= 0) {
        clearInterval(interval);
        this.showResendEmailCodeTimerCounter = false;
      }
    }, 1000);
  }
}