import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
    providedIn: 'root'
})

export class VendorProductsService {

    constructor(
        private restangular: Restangular
    ) {
    }

    getAllVendorProducts(): Promise<any> {
        return this.restangular.one('vendors').one('products').one('all').getList().toPromise();
    }

    getUniqueVendorProducts(): Promise<any> {
        return this.restangular.one('vendors').one('products').one('unique').getList().toPromise();
    }

}
