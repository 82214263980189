import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Dictionary} from 'highcharts';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UtilsService} from '../../../services/utils/utils.service';
import moment from 'moment';

export interface Field {
  name: string;
  label: string;
  fieldType: FieldType;
  values?: Array<string>;
  pattern?: string;
  required?: boolean;
  readOnly?: boolean;
  minDate?: string;
  maxDate?: string;
  dateCutoffField?: string;
  invisible?: (arg0: any) => boolean;
  hidePassword?: boolean;
}

export enum FieldType {
  F_STRING,
  F_NUMBER,
  F_PASTDATE,
  F_FUTUREDATE,
  F_EMAIL,
  F_DROPDOWN,
  F_PHONE,
  F_SSN,
  F_STATE,
  F_JSON
}

@Component({
  selector: 'app-member-addedit',
  templateUrl: './member-addedit.component.html',
  styleUrls: ['./member-addedit.component.scss']
})
export class MemberAddeditComponent implements OnInit {

  protected form!: UntypedFormGroup;
  protected fieldType = FieldType;
  protected errorMessage = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<MemberAddeditComponent>,
    private utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: MemberAddeditDialogModel,
  ) {
    this.data = data;
    const controls: Dictionary<any> = {};
    this.data.fields.forEach(field => {
      const validators = field.required ? [Validators.required] : [];
      if (field.fieldType === FieldType.F_EMAIL) {
        validators.push(Validators.email); // Add email-specific validation
      }
      if (field.pattern) {
        validators.push(Validators.pattern(field.pattern)); // Preserve support for custom patterns
      }
      if (field.fieldType === FieldType.F_SSN) {
        field.hidePassword = true;
      }
      if (field.fieldType === FieldType.F_PASTDATE) {
        const today = moment().add(1, 'days');
        const hundredYearsAgo = moment().subtract(100, 'years');
        field.minDate = hundredYearsAgo.format('YYYY-MM-DD');
        field.maxDate = today.format('YYYY-MM-DD');
      }

      if (field.fieldType === FieldType.F_FUTUREDATE) {
        const today = new Date();
        const oneYearInTheFuture = new Date();
        oneYearInTheFuture.setFullYear(today.getFullYear() + 1);
        field.maxDate = oneYearInTheFuture.toISOString().split('T')[0];
        field.minDate = field.dateCutoffField && this.data.data ? this.data.data[this.data.fields.find(thisField => thisField.name === field.dateCutoffField)?.name || 0] : today.toISOString().split('T')[0];
      }

      if (field.fieldType === FieldType.F_PASTDATE || field.fieldType === FieldType.F_FUTUREDATE) {
        if (this.data.data) {
          this.data.data[field.name] = moment(this.data.data[field.name]).toISOString();
        }
      }
      controls[field.name] = new UntypedFormControl(
        this.data.data ? this.data.data[field.name] : null,
        validators
      );
    });

    this.form = this.formBuilder.group(controls);
    this.utilsService.getFormErrors(this.form, errorMessage => this.errorMessage = errorMessage, this.data.fields);
    this.form.valueChanges.subscribe(changes => {
      this.utilsService.getFormErrors(this.form, errorMessage => this.errorMessage = errorMessage, this.data.fields);
    });
  }

  ngOnInit(): void {
  }

  okClicked(): void {
    // tslint:disable-next-line:forin
    /*
    for (const key in this.form.value) {
      const dateField = this.data.fields.find(field => field.name === key && (field.fieldType === FieldType.F_FUTUREDATE || field.fieldType === FieldType.F_PASTDATE));
      if (dateField) {
        console.log(key, this.form.value[key])
        this.form.value[key] = this.form.value[key].split('T')[0];
      }
    }
     */
    this.dialogRef.close(this.form.value);
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }

  getElementById(id: string): any {
    return document.getElementById(id);
  }

}

export class MemberAddeditDialogModel {
  constructor(public data: Dictionary<any> | null, public fields: Array<Field>, public title: string) {
  }
}

