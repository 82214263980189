import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class VendorGroupsRatesService {

  constructor(
      private restangular: Restangular
  ) { }

  getAllVendorGroupRates(): Promise<any> {
    return this.restangular.one('/vendors/groups/rates').get().toPromise();
  }

  createVendorGroupRate(productId: string, companyId: string, metadata: any): Promise<any> {
    return this.restangular.one('/vendors/groups/rates', productId).one(companyId).customPOST({
      ...metadata,
    }).toPromise();
  }

  modifyVendorGroupRate(id: string, fields: any): Promise<any> {
    return this.restangular.one('/vendors/groups/rates', id).customPUT({
      ...fields
    }).toPromise();
  }

  deleteVendorGroupRate(id: string): Promise<any> {
    return this.restangular.one('vendors/groups/rates', id).remove().toPromise();
  }

}
