interface OjbectInterface {
    [key: string]: string;
}

export function findPartialKey(object: OjbectInterface, partialKey: string): string | void {
    for (const key in object) {
        if (key.toLowerCase().includes(partialKey.toLowerCase())) {
            return object[key];
        }
    }
}

export function findPartialKeyRelatedToCognito(object: OjbectInterface, partialKey: string): string | void {
    for (const key in object) {
        if (key.toLowerCase().startsWith('CognitoIdentityServiceProvider'.toLowerCase()) && key.toLowerCase().endsWith(partialKey.toLowerCase())) {
            return object[key];
        }
    }
}

