import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SessionService} from '../../services/session/session.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-token',
  templateUrl: './token.page.html',
  styleUrls: ['./token.page.scss'],
})
export class TokenComponent implements OnInit {

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private sessionService: SessionService,
      private spinner: SpinnerService,
      private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.spinner.show('Loading...');
    this.route.params.subscribe(async (params: Params): Promise<void> => {
      try {
        localStorage.clear();
        this.sessionService.setToken(params.token, params.refresh);
        this.sessionService.createTeam = 'create' in params;
        await this.sessionService.getRefreshTokenCognito();
        localStorage.removeItem('selected-wellspace');
        void this.router.navigate(['']);
        this.spinner.hide();
      } catch (error) {
        this.spinner.hide();
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'An error occurred while being redirected'});
        console.error('An error occurred while being redirected: ', error);
        this.sessionService.logout();
      }
    });
  }

}
