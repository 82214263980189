<h1>Approve Member Purchases</h1>
<nav mat-tab-nav-bar [tabPanel]="tabs">
<a mat-tab-link *ngFor="let link of links" [routerLink]="link.link"
     (click)="activeLink = link"
     [active]="activeLink == link">{{link.title}}</a>
</nav>
<app-analytics-toapprove *ngIf="activeLink.title === 'Transactions'"></app-analytics-toapprove>
<app-analytics-approve *ngIf="activeLink.title === 'Receipts to Approve'" [filter]="''" [activeTitle]="activeLink.title"></app-analytics-approve>
<app-analytics-approve *ngIf="activeLink.title === 'Approved Receipts'" [filter]="'Reimbursement approved'" [activeTitle]="activeLink.title"></app-analytics-approve>
<app-analytics-approve *ngIf="activeLink.title === 'Denied Receipts'" [filter]="'Reimbursement denied'" [activeTitle]="activeLink.title"></app-analytics-approve>
<app-analytics-approve *ngIf="activeLink.title === 'Reimbursed Receipts'" [filter]="'Reimbursed'" [activeTitle]="activeLink.title"></app-analytics-approve>
<app-analytics-configure *ngIf="activeLink.title === 'Configure Approvers'"></app-analytics-configure>
<mat-tab-nav-panel #tabs></mat-tab-nav-panel>
