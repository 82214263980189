import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {TeamCompanyUsersService} from '../../../services/teamcompanyusers/teamcompanyusers.service';

export let rememberedAgencyId = '';

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss']
})
export class AgencyComponent implements OnInit {

  public links = [
    { title: 'Profile', link: '/brokeragency/agency/agency-profile'},
    { title: 'Clients', link: '/brokeragency/agency/agency-clients'},
    { title: 'Members', link: '/brokeragency/agency/agency-members'},
    { title: 'Producers', link: '/brokeragency/agency/agency-producers'}
  ];

  public activeLink: any;
  public selectedAgencyId = '';
  public agencyName = '';
  public agencies: any = [];
  public info = [
    {title: 'Clients', value: 0, background: 'lightskyblue'},
    {title: 'Members', value: 0, background: 'orange'},
    {title: 'Producers', value: 0, background: 'yellowgreen'},
  ];

  constructor(
      private router: Router,
      private teamCompaniesService: TeamCompaniesService,
      private teamCompanyUsersService: TeamCompanyUsersService
  ) { }

  ngOnInit(): void {
    this.selectedAgencyId = rememberedAgencyId;
    const parsedUrl = this.router.parseUrl(this.router.url);
    const urlSegments = parsedUrl.root.children.primary.segments;
    urlSegments.length = 3;
    this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
    this.teamCompaniesService.getCompanies(this.teamCompanyUsersService.roles.broker ? 'broker' : 'agency').then((companies: any) => {
      this.agencies = companies.filter((company: { type: string; }) => company.type === 'agency');
      if (!this.selectedAgencyId) {
        if (this.agencies.length > 0) {
          this.setAgency({value: this.agencies[0].id});
        } else {
          this.setAgency({value: this.teamCompanyUsersService.roles.agency?.id});
          this.agencyName = this.teamCompanyUsersService.roles.agency?.name;
        }
      }
    });

  }

  setCount(fieldName: string, value: number): void {
    // @ts-ignore
    this.info.find(item => item.title === fieldName).value = value;
    this.info = this.info.slice();
  }

  gotoTab(link: string): void {
    this.router.navigate([link]);
    this.activeLink = link;
  }

  setAgency(event: any): void {
    this.selectedAgencyId = event.value;
    rememberedAgencyId = this.selectedAgencyId;
  }

}