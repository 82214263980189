<div class="topline">
    <h1>Clients</h1>
    <app-inlinemessage level="warning" *ngIf="noPartner" message="No Partner Brand Set for Client"></app-inlinemessage>
    <mat-form-field *ngIf="clients?.length > 0">
        <mat-label>Current Client</mat-label>
        <mat-select (selectionChange)="setClient($event)" [(value)]="selectedClientId">
            <mat-option *ngFor="let client of clients" [value]="client.id">{{client.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <h1 *ngIf="clients?.length === 0">{{clientName}}</h1>
</div>
<app-infoband [items]="info"></app-infoband>
<nav mat-tab-nav-bar [tabPanel]="tabs">
    <a mat-tab-link *ngFor="let link of links" (click)="gotoTab(link.link)"
       [active]="activeLink == link">{{link.title}}</a>
</nav>
<app-client-census [clientId]="selectedClientId" *ngIf="activeLink?.title === 'Census'"></app-client-census>
<app-client-eligibility [clientId]="selectedClientId" *ngIf="activeLink?.title === 'Eligibility'"></app-client-eligibility>
<app-client-enrollment [clientId]="selectedClientId" *ngIf="activeLink?.title === 'Enrollment'"></app-client-enrollment>
<mat-tab-nav-panel #tabs></mat-tab-nav-panel>