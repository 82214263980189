<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{data.data ? 'Edit' : 'Add New'}} {{data.title}}
</h1>
<div mat-dialog-content>
    <form [formGroup]="form">
        <ng-container *ngFor="let field of data.fields; let i = index">
            <mat-form-field appearance="fill" floatLabel="always" *ngIf="!field.invisible?.(form)">
                <mat-label>{{field.label}}</mat-label>

                <!-- String Input -->
                <input *ngIf="field.fieldType === fieldType.F_STRING" matInput placeholder="" [formControlName]="field.name">

                <!-- Number Input -->
                <input *ngIf="field.fieldType === fieldType.F_NUMBER" type="number" matInput placeholder="" [formControlName]="field.name">

                <!-- SSN Input -->
                <input *ngIf="field.fieldType === fieldType.F_SSN" [type]="field.hidePassword ? 'password' : 'text'" pattern="^\d{3}-?\d{2}-?\d{4}$" matInput placeholder="" [formControlName]="field.name">
                <button *ngIf="field.fieldType === fieldType.F_SSN" mat-icon-button matSuffix (click)="field.hidePassword = !field.hidePassword" [attr.aria-label]="'Toggle password visibility'" [attr.aria-pressed]="!field.hidePassword">
                    <mat-icon>{{ field.hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>

                <!-- Phone Input -->
                <input *ngIf="field.fieldType === fieldType.F_PHONE" type="tel" matInput placeholder="" [formControlName]="field.name">

                <!-- Email Input -->
                <input *ngIf="field.fieldType === fieldType.F_EMAIL" type="email" matInput placeholder="" [formControlName]="field.name">
                <mat-error *ngIf="form.get(field.name)?.hasError('required')">
                    {{field.label}} is required
                </mat-error>
                <mat-error *ngIf="form.get(field.name)?.hasError('email')">
                    Please enter a valid email address
                </mat-error>

                <!-- Past/Future Date Input -->
                <input *ngIf="field.fieldType === fieldType.F_PASTDATE || field.fieldType === fieldType.F_FUTUREDATE"
                       [matDatepicker]="picker" matInput placeholder=""
                       [formControlName]="field.name" [min]="field.minDate" [max]="field.maxDate">
                <mat-datepicker-toggle matSuffix [for]="picker"
                                       *ngIf="field.fieldType === fieldType.F_PASTDATE || field.fieldType === fieldType.F_FUTUREDATE">
                </mat-datepicker-toggle>

                <!-- Dropdown -->
                <mat-select *ngIf="field.fieldType === fieldType.F_DROPDOWN" [formControlName]="field.name">
                    <mat-option *ngFor="let value of field.values" [value]="value.split(':')[1] || value">
                        {{value.split(':')[0]}}
                    </mat-option>
                </mat-select>
                <app-state *ngIf="field.fieldType === fieldType.F_STATE" [formControlName]="field.name"></app-state>
                <app-json *ngIf="field.fieldType === fieldType.F_JSON" [formControlName]="field.name"></app-json>
            </mat-form-field>
            <mat-datepicker #picker></mat-datepicker>
        </ng-container>
    </form>
    <span class="required">* Required Field</span>
    <app-inlinemessage *ngIf="errorMessage" level="error" [message]="errorMessage"></app-inlinemessage>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="!form.valid" class="donebutton" color="primary" (click)="okClicked()">OK</button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>