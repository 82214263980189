import { Inject, Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { LocalStorageService } from '../localstorage/localstorage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Dictionary } from 'highcharts';
import { Restangular } from 'ngx-restangular';
import { AppSettings } from '../../app.settings';
import { RESTANGULAR_INTEGRATION_SERVER } from '../../app.module';
import { HttpClient } from '@angular/common/http';
import {CognitoMfaService} from '../cognito/cognito-mfa.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    JWT_TOKEN = 'JWTToken';
    REFRESH_TOKEN = 'refreshToken';

    public loginUrl = '';

    public createTeam = false;

    private isIntegrationAdmin = AppSettings.isIntegration();

    constructor(
        private apiService: ApiService,
        private restangular: Restangular,
        private localStorageService: LocalStorageService,
        @Inject(RESTANGULAR_INTEGRATION_SERVER) private integrationRestangular: Restangular,
        private http: HttpClient,
        private cognitoMFAService: CognitoMfaService,
        private router: Router
    ) { }

  login(userId: string): Promise<any> {
    return this.apiService.post(`admin/userLogin/${userId}`, {});
  }

    loginByUserId(userId: string): void {
        this.login(userId).then(data => {
            const token = data.v3Url.match(/\/token\/.*/);
            window.open(`${window.location.origin}${token}?api=${this.apiService.apiName}`, '_blank');
        });
    }

    async loginUserPassword(email: string, password: string): Promise<any> {
        const user: {username: string, password: string} = {username: email, password};
        await this.cognitoAuthMethods(user).then(() => {
            if (this.isIntegrationAdmin) {
                return this.integrationRestangular.one('auth/user').customPOST({ user_email: email, user_password: password }).toPromise().then(async (result: Dictionary<any>) => {
                    this.setToken(result.session_token, result.refresh_token);
                    await this.cognitoMFAService.saveCognitoRefreshTokenInformation();
                    return result;
                });
            } else {
                return this.apiService.post('auth/user-password', { username: email, password }).then(async data => {
                    const parts = data.url.split('/').slice(-2);
                    this.setToken(parts[0], parts[1]);
                    await this.cognitoMFAService.saveCognitoRefreshTokenInformation();
                    return data;
                });
            }
        }).catch(err =>  {
            console.error('Error in cognito auth method: ', err);
            return Promise.reject(`'Error in cognito auth method: ', ${err}`);
        });
    }

    async cognitoAuthMethods(user: {username: string, password: string}): Promise<void> {
        try {
            const userAlreadyExist: boolean | null = await this.cognitoMFAService.checkIfUserExist(user);
            if (userAlreadyExist === null) {
                return Promise.reject();
            }
            if (userAlreadyExist) {
                const cognitoUserResponse: any = await this.cognitoMFAService.cognitoMFASignIn(user);
                if (cognitoUserResponse && cognitoUserResponse.challengeName === 'SOFTWARE_TOKEN_MFA' && !cognitoUserResponse.deviceKey) {
                    void this.router.navigate(['2fa/app/add-code', user.username, 'secret'], {state: user});
                    return Promise.reject('SOFTWARE_TOKEN_MFA Required');
                }
            } else {
                await this.cognitoMFAService.cognitoMFASignUp(user);
                await this.cognitoMFAService.cognitoMFASignIn(user);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    public loginValidateCode(phoneNumber: string, code: string): Promise<any> {
        return this.http.post(AppSettings.getEndpoint() + `auth/mobile/${phoneNumber}/verificationCode/${code}`, {}, { withCredentials: true }).toPromise().then(async (data: any) => {
            this.parseTokens(data);
            await this.cognitoMFAService.saveCognitoRefreshTokenInformation();
            return data;
        });
    }

    logout(): void {
        localStorage.clear();
        this.apiService.unsetToken();
        this.localStorageService.removeStorage(this.JWT_TOKEN);
        this.localStorageService.removeStorage(this.REFRESH_TOKEN);
    }

    private parseTokens(data: any): void {
        const parts = data.id.split('/').slice(-2);
        this.setToken(parts[0], parts[1]);
    }

    public setToken(token: string, refreshToken = null): void {
        this.apiService.setToken(token);
        this.localStorageService.setStorage(this.JWT_TOKEN, token);
        if (refreshToken) {
            this.localStorageService.setStorage(this.REFRESH_TOKEN, refreshToken);
        }
    }

    deletePhoneNumber(tel: string): Promise<any> {
        return this.restangular.one('mobile', tel).remove().toPromise();
    }

    public decodeJWTToken(jwt = this.localStorageService.getStorage(this.JWT_TOKEN)): Dictionary<any> | null {
        try {
            const helper = new JwtHelperService();
            return helper.decodeToken(jwt);
        } catch (err) {
            return null;
        }
    }

    public getUserId(): string {
        const token = this.decodeJWTToken();
        return token ? token.userId || token.info.user_id : '';
    }

    private getUuid(): string {
        const token = this.decodeJWTToken();
        return token ? token?.info?.uuid || '' : '';
    }

    public isSuperAdmin(): boolean {
        const ADMIN_PERMISSION = 4096;
        const token = this.decodeJWTToken();
        // tslint:disable-next-line:no-bitwise
        return token ? (token.permissionLevel & ADMIN_PERMISSION) !== 0 : false;
    }

    public getCompany(): Promise<Dictionary<any>> {
        return this.integrationRestangular.one('companies').one(this.getUuid()).get().toPromise();
    }

    public getUser(): Promise<Dictionary<any>> {
        return this.integrationRestangular.one('users').one(this.getUserId()).get().toPromise();
    }

    createAccount(user: any): Promise<any> {
        return this.restangular.one('auth').all('create-user-password').post(user).toPromise();
    }

    getRandomPassword(): Promise<any> {
        return this.restangular.one('admin').one('generateInviteExample').get().toPromise().then((result: { randomInvite: any; }) => result.randomInvite);
    }

    async verifyEmailCode(email: string, code: string): Promise<any> {
        try {
            await this.cognitoMFAService.cognitoCustomChallengeAnswer(code);
            const data = await this.http.post<any>(
                `${AppSettings.getEndpoint()}auth/validate/login-without-pass/${code}`,
                { email },
                {
                    withCredentials: true,
                    headers: { 'Content-Type': 'application/json' }
                }
            ).toPromise();
            this.setToken(data.id, data.refreshToken);
            await this.cognitoMFAService.saveCognitoRefreshTokenInformation();
            setTimeout(() => this.router.navigate(['']), 3000);
            return data;
        } catch (error) {
            console.error('Erro ao verificar código de email:', error);
            throw error;
        }
    }

    async getRefreshTokenCognito(): Promise<void> {
        try {
            const userId: string = this.getUserId();
            const jwtToken = this.localStorageService.getStorage('JWTToken');
            const data = await this.http.get<any>(
                `${AppSettings.getEndpoint()}auth/refresh-cognito/${userId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`,
                    }
                },
            ).toPromise();
            const response = await this.cognitoMFAService.getAccessTokenUsingRefreshToken(
                data.refreshToken,
                data.deviceKey,
            );
            this.localStorageService.setStorage(
                'CognitoIdentityServiceProvider.accessToken',
                response.AuthenticationResult.AccessToken,
            );
            this.localStorageService.setStorage(
                'CognitoIdentityServiceProvider.idToken',
                response.AuthenticationResult.IdToken,
            );
        } catch (error) {
            console.log('Error while getting cognito refresh token:', error);
            throw error;
        }
    }
}
