import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output} from '@angular/core';
import {JsonEditorOptions} from 'ang-jsoneditor';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {TeamsService} from '../../../services/teams/teams.service';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {Spinner} from '@angular-devkit/build-angular/src/utils/spinner';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {UtilsService} from '../../../services/utils/utils.service';

@Component({
  selector: 'app-editcompanydialog',
  templateUrl: './editcompanydialog.component.html',
  styleUrls: ['./editcompanydialog.component.scss']
})
export class EditCompanyDialogComponent implements OnInit, OnChanges {

  @Input() companyId = '';
  @Output() savedEvent: EventEmitter<any> = new EventEmitter<any>();

  public company;
  public form: any;
  public mode;
  isModal = false;
  private imageData = null;
  public typeName = 'Company';
  protected errorMessage = '';

  constructor(
      private formBuilder: UntypedFormBuilder,
      @Optional() private dialogRef: MatDialogRef<EditCompanyDialogComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: EditCompanyDialogModel,
      private teamsService: TeamsService,
      private teamCompaniesService: TeamCompaniesService,
      private spinnerService: SpinnerService,
      private utilsService: UtilsService
  ) {
    this.company = data?.company;
    this.mode = data?.mode;
    this.isModal = !!data;
    if (data?.modeTitle) {
      this.typeName = data.modeTitle;
    }
    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      name: new UntypedFormControl(this.company?.name, [Validators.required]),
      type: new UntypedFormControl(this.company?.type, [Validators.required]),
      domainName: new UntypedFormControl(this.company?.domainName),
      payrollConfig: new UntypedFormControl(this.company?.payrollConfig),
      SIC: new UntypedFormControl(this.company?.SIC),
      EIN: new UntypedFormControl(this.company?.EIN),
      address1: new UntypedFormControl(this.company?.address1),
      address2: new UntypedFormControl(this.company?.address2),
      city: new UntypedFormControl(this.company?.city),
      state: new UntypedFormControl(this.company?.state, [Validators.required]),
      postcode: new UntypedFormControl(this.company?.postcode),
      logoUrl: new UntypedFormControl(this.company?.logoUrl)
    });
    if (this.mode) {
      this.form.get('type').setValue(this.mode);
    }
    this.utilsService.getFormErrors(this.form, errorMessage => this.errorMessage = errorMessage);
    this.form.valueChanges.subscribe(() => {
      this.utilsService.getFormErrors(this.form, errorMessage => this.errorMessage = errorMessage);
    });
  }

  ngOnInit(): void {
    if (this.companyId) {
      this.teamCompaniesService.getCompany(this.companyId).then(company => {
        this.company = company;
        this.buildForm();
      });
    }
  }

  ngOnChanges(): void{
    this.ngOnInit();
  }

  editorOptions(): any {
    const options = new JsonEditorOptions();
    options.modes = ['text', 'tree', 'view'];
    return options;
  }

  private getFormField(name: string): any {
    const field = this.form.get(name);
    return field ? field.value : undefined;
  }

  private getFormData(): any {
    return {
      name: this.getFormField('name'),
      type: this.mode ? this.mode : this.getFormField('type'),
      domainName: this.getFormField('domainName'),
      payrollConfig: this.getFormField('payrollConfig'),
      SIC: this.getFormField('SIC'),
      EIN: this.getFormField('EIN'),
      address1: this.getFormField('address1'),
      address2: this.getFormField('address2'),
      city: this.getFormField('city'),
      state: this.getFormField('state'),
      postcode: this.getFormField('postcode'),
      imageData: this.imageData
    };
  }

  okClicked(): void {
    if (this.dialogRef) {
      this.dialogRef.close(this.getFormData());
    }
  }

  saveClicked(): void {
    this.savedEvent.emit(this.getFormData());
  }

  onDismiss(): void {
    if (this.dialogRef) {
      this.dialogRef.close(undefined);
    }
  }

  imageUploaded(img: any): void {
    this.imageData = img;
  }
}

export class EditCompanyDialogModel {
  constructor(public company: any, public mode: 'broker' | 'agency' | 'employer' | undefined, public modeTitle: string = '') {
  }
}
