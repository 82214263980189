import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {VendorGroupsProductsService} from '../../../services/vendor-groups-products/vendor-groups-products.service';
import {FieldType, MemberAddeditComponent, MemberAddeditDialogModel} from '../member-addedit/member-addedit.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {VendorProductsService} from '../../../services/vendorproducts/vendorproducts.service';

@Component({
  selector: 'app-vendor-groups-products',
  templateUrl: './vendor-groups-products.component.html',
  styleUrls: ['./vendor-groups-products.component.scss']
})
export class VendorGroupsProductsComponent implements OnInit {

  protected allColumnNames = ['vendorSlug', 'metadata', 'actionmenu'];
  protected items = new MatTableDataSource<any>();

  private addEditFields = [
    {name: 'vendorSlug', label: 'Vendor', fieldType: FieldType.F_DROPDOWN, values: [], required: true},
    {name: 'metadata', label: 'Configuration JSON', fieldType: FieldType.F_JSON, required: true},
  ];

  constructor(
      private vendorGroupsProductsService: VendorGroupsProductsService,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService,
      private vendorProductsService: VendorProductsService
  ) {

  }

  ngOnInit(): void {
    this.reloadItems();
  }

  private reloadItems(): void {
    this.vendorGroupsProductsService.getAllVendorGroupProducts().then(products => {
      this.items.data = products;
    });
    this.vendorProductsService.getUniqueVendorProducts().then(vendors => {
      this.addEditFields[0].values = vendors.map((vendor: { vendorName: any; vendorSlug: any; }) => `${vendor.vendorName}:${vendor.vendorSlug}`);
    });
  }

  addItem(): void {
    const dialogRef = this.dialog.open(MemberAddeditComponent, {
      data: new MemberAddeditDialogModel(null, this.addEditFields, 'Group Product')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.vendorGroupsProductsService.createVendorGroupProduct(dialogResult.vendorSlug, dialogResult.metadata).then(() => {
          this.reloadItems();
          this.snackBar.open('New group product created');
        });
      }
    });
  }

  editItem(item: any): void {
    const dialogRef = this.dialog.open(MemberAddeditComponent, {
      data: new MemberAddeditDialogModel(item, this.addEditFields, 'Group Product')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.vendorGroupsProductsService.modifyVendorGroupProduct(item.id, dialogResult).then(() => {
          this.reloadItems();
          this.snackBar.open('Group product updated');
        });
      }
    });
  }

  duplicateItem(item: any): void {
    this.vendorGroupsProductsService.createVendorGroupProduct(item.vendorSlug, item.metadata).then(() => {
      this.reloadItems();
      this.snackBar.open('Duplicate group product created');
    });
  }

  removeItem(item: any): void {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this group product?').then(ok => {
      this.vendorGroupsProductsService.deleteVendorGroupProduct(item.id).then(() => {
        this.reloadItems();
        this.snackBar.open('Group product removed');
      });
    });
  }

  truncateJSONString(str: string, maxLength: number): string {
    str = JSON.stringify(str);
    return str.length <= maxLength ? str : str.slice(0, maxLength - 3) + '...';
  }

}
