<h1>Reconciliation</h1>
<nav mat-tab-nav-bar [tabPanel]="tabs">
    <a mat-tab-link *ngFor="let link of links" [routerLink]="link.link"
       (click)="activeLink = link"
       [active]="activeLink == link">{{link.title}}</a>
</nav>
<app-reconciliation-stripe *ngIf="activeLink.title === 'Stripe Transactions'" [info]="info"></app-reconciliation-stripe>
<app-reconciliation-teamlog *ngIf="activeLink.title === 'Team Log' "[info]="info"></app-reconciliation-teamlog>
<app-reconciliation-apilog *ngIf="activeLink.title === 'API Log'" [info]="info"></app-reconciliation-apilog>
<mat-tab-nav-panel #tabs></mat-tab-nav-panel>
