import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init as initApm } from '@elastic/apm-rum';
import { AppSettings } from './app/app.settings';

initApm({
  serviceName: `Team-admin`,
  serverUrl: 'https://1bbb167b77a4401b85fa51148a1fdd6a.apm.us-west-2.aws.cloud.es.io/',
  serviceVersion: 'v1',
  environment: `${AppSettings.getCurrentEndpointEnv()}`
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
