import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {UsersInsuranceService} from '../../../services/usersinsurance/users.insurance.service';
import {TeamCompanyUsersService} from '../../../services/teamcompanyusers/teamcompanyusers.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

let rememberedClientId = '';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  public selectedClientId = '';
  public clientName = '';
  public clients: any;
  public info: any;
  public noPartner = false;

  public links = [
    { title: 'Census', link: '/brokeragency/clients/client-census'},
    { title: 'Eligibility', link: '/brokeragency/clients/client-eligibility'},
    { title: 'Enrollment', link: '/brokeragency/clients/client-enrollment'}
  ];

  public activeLink: any;

  constructor(
      private teamCompaniesService: TeamCompaniesService,
      private router: Router,
      private usersInsuranceService: UsersInsuranceService,
      private teamCompanyUsersService: TeamCompanyUsersService,
      private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.selectedClientId = rememberedClientId;
    const parsedUrl = this.router.parseUrl(this.router.url);
    const urlSegments = parsedUrl.root.children.primary.segments;
    urlSegments.length = 3;
    this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
    this.teamCompaniesService.getCompanies(this.teamCompanyUsersService.roles.broker ? 'broker' : this.teamCompanyUsersService.roles.agency ? 'agency' : this.teamCompanyUsersService.roles.member ? 'member' : 'client').then((companies: any) => {
      this.clients = companies.filter((company: { type: string; }) => company.type === 'employer');
      this.setNoPartner();
      if (this.teamCompanyUsersService.roles.member) {
        if (!this.teamCompanyUsersService.roles.member.metadata.clients) {
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'This member is not assigned as a producer for any clients.'});
        }
        this.clients = this.clients.filter((company: { id: any; }) => this.teamCompanyUsersService.roles.member.metadata.clients?.indexOf(company.id) >= 0);
      }
      if (!this.selectedClientId) {
        if (this.clients.length > 0) {
          this.setClient({value: this.clients[0].id});
        } else {
          this.setClient({value: this.teamCompanyUsersService.roles.client?.id});
          this.clientName = this.teamCompanyUsersService.roles.client?.name;
        }
        this.getCounts();
      }
    });
    this.usersInsuranceService.insuranceStateChangedObservable.subscribe(() => {
      this.getCounts();
    });
  }

  private setNoPartner(): void {
    this.noPartner = !this.clients.find((client: { id: string; }) => client.id === this.selectedClientId)?.payrollConfig?.partners;
  }

  private getCounts(): void {
    if (this.selectedClientId) {
      this.usersInsuranceService.getCounts(this.selectedClientId).then(result => {
        this.info = [
          {title: 'Census', value: result.census, background: 'lightskyblue'},
          {title: 'Eligible', value: result.eligible, background: 'orange'},
          {title: 'Enrolled', value: result.enrolled, background: 'mediumturquoise'},
          {title: 'Invited', value: result.invited, background: 'mediumorchid'},
          {title: 'Signed Up', value: result.signedup, background: 'yellowgreen'},
        ];
      });
    }
  }

  gotoTab(link: string): void {
    this.router.navigate([link]);
    this.activeLink = link;
  }

  setClient(event: any): void {
    this.selectedClientId = event.value;
    this.getCounts();
    rememberedClientId = this.selectedClientId;
    this.setNoPartner()
  }

}