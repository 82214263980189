<h1>Billing</h1>
<span class="achinfo" *ngIf="badgeCount > 0">You have {{badgeCount}} pending ACH transfer{{badgeCount === 1 ? '' : 's'}}</span>
<span class="balance">Account balance {{-balance | currency}}</span>
<nav mat-tab-nav-bar [tabPanel]="tabs">
<a mat-tab-link *ngFor="let link of links" [routerLink]="link.link"
     (click)="activeLink = link"
     [active]="activeLink == link">{{link.title}}</a>
</nav>
<app-stripepaymentmethods *ngIf="activeLink.title === 'Payment Methods'"></app-stripepaymentmethods>
<app-stripe-available-payment-methods *ngIf="activeLink.title === 'Add Available Payment Method'"></app-stripe-available-payment-methods>
<app-stripecreditcard *ngIf="activeLink.title === 'Add Payment Method'"></app-stripecreditcard>
<app-stripe-chargehistory *ngIf="activeLink.title === 'Charge History'"></app-stripe-chargehistory>
<mat-tab-nav-panel #tabs></mat-tab-nav-panel>
